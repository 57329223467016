import NavFactory from "@/library/NavFactory";

let routes = [
    {
        name: 'Schools',
        icon: 'location_city',
        path: '/schools',
        permission: 'school-read',
        service:true,

    },
    {
        name: 'Payments',
        icon: 'payments',
        path: '/school-payments',
        permission: 'school-create',
        service:true,

    },
    {
        name: 'Roles',
        icon: 'security',
        path: '/roles',
        service:true,
        permission: 'roles-read'
    },
    {
        name: 'Members',
        icon: 'group',
        header: true,
        service:true,
        permission: 'guardian-read|staff-read',
        children: [
            {
                name: 'Staff',
                path: '/staffs',
                icon: 'perm_identity',
                permission: 'staff-read'
            },
            {
                name: 'Staff Attendance',
                path: '/staffs-attendances',
                icon: 'perm_identity',
                permission: 'staff-read'
            },
            {
                name: 'Guardians',
                path: '/guardians',
                icon: 'supervised_user_circle',
                permission: 'guardian-read'
            }
        ]
    },
    {
        name: 'Services & Modules',
        icon: 'ballot',
        header: true,
        permission: 'services-read|modules-read',
        service:true,
        children: [
            {
                name: 'Services',
                path: '/services',
                icon: 'room_service',
                permission: 'services-read'
            },
            {
                name: 'Modules',
                path: '/modules',
                icon: 'gamepad',
                permission: 'modules-read'
            }
        ]
    },
    {
        name: 'Authentication Enabler',
        icon: 'vpn_key',
        permission: 'staff-create',
        service:true,
        path: '/authentication-enabler',
    },
    {
        name: 'Deleted Users',
        icon: 'group',
        path: '/deleted-users',
        permission: 'school-read',
        service:true,

    },
    {
        name: 'Referal School',
        icon: 'group',
        path: '/referal-school',
        permission: 'school-read',
        service:true,

    },
    {
        name: 'School Services',
        icon: 'group',
        path: '/school-services',
        permission: 'school-read',
        service:true,

    },

];

export default routes;